import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import AuthService from "../../../../services/auth-service";

const InfoTable = () => {
    const [columns] = useState([
        { Header: "IP Adresas", accessor: "ip_address", width: "20%", align: "left" },
        { Header: "Naudotojo agentas", accessor: "user_agent", width: "40%", align: "left" },
        { Header: "Data ir laikas", accessor: "datetime", width: "30%", align: "left" },
    ]);

    const [rows, setRows] = useState([]);

    const fetchData = async () => {
        try {
            const infoData = await AuthService.fetchInfo();

            const rowsData = infoData.slice(0, 20 || infoData.length).map((info) => ({
                ip_address: (
                    <MDTypography component="a" href="#" variant="button" color="dark" fontWeight="medium">
                        {info.ip_address}
                    </MDTypography>
                ),
                user_agent: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {info.user_agent}
                    </MDTypography>
                ),
                datetime: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {new Date(info.datetime).toLocaleString('lt-LT')}
                    </MDTypography>
                ),
            }));
            setRows(rowsData);
        } catch (error) {
            console.error("There was an error fetching the info data!", error);
        }
    };

    useEffect(() => {
        fetchData().then(r => {

        });
    }, []);

    return (
        <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Paskutiniai unikalūs lankytojai
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <DataTable
                                table={{ columns, rows }}
                                isSorted={true}
                                canSearch={false}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                noEndBorder
                            />
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default InfoTable;
