import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const BlockIpModal = ({ open, handleClose, handleSave }) => {
    const [ip, setIp] = useState('');

    const handleChange = (e) => {
        setIp(e.target.value);
    };

    const handleSubmit = () => {
        handleSave(ip);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 2,
                }
            }}
        >
            <DialogTitle sx={{ bgcolor: 'background.default', color: 'primary.contrastText', py: 2 }}>
                Pridėti IP adresą
            </DialogTitle>
            <DialogContent sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
                <TextField
                    autoFocus
                    margin="dense"
                    name="ip"
                    label="IP Adresas"
                    type="text"
                    fullWidth
                    value={ip}
                    onChange={handleChange}
                    sx={{ color: 'text.primary', py: 2 }}
                />
            </DialogContent>
            <DialogActions sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
                <Button onClick={handleClose} color="primary">
                    Atšaukti
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Išsaugoti
                </Button>
            </DialogActions>
        </Dialog>
    );
};

BlockIpModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
};

export default BlockIpModal;
