import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Autocomplete } from "@mui/material";

function DataTable({
                       entriesPerPage = { defaultValue: 10, entries: [5, 10, 15, 20, 25, 100] } || false,
                       canSearch = false,
                       showTotalEntries = true,
                       table,
                       pagination = { variant: "gradient", color: "info" },
                       isSorted = true,
                       noEndBorder = false,
                   }) {
    const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
    const entries = entriesPerPage.entries
        ? entriesPerPage.entries.map((el) => el.toString())
        : ["5", "10", "15", "20", "25", "100"];
    const columns = useMemo(() => table.columns, [table]);
    const [data, setData] = useState(table.rows);
    const [sortState, setSortState] = useState({});

    const extractTextContent = (element) => {
        if (typeof element === 'string' || typeof element === 'number') {
            return element.toString();
        } else if (element && element.props && element.props.children) {
            return extractTextContent(element.props.children);
        } else if (Array.isArray(element)) {
            return element.map(child => extractTextContent(child)).join(' ');
        }
        return '';
    };

    const globalFilterFunction = (rows, columnIds, filterValue) => {
        if (!filterValue) return rows;

        const lowercasedFilter = filterValue.toLowerCase();
        return rows.filter((row) => {
            return columnIds.some((columnId) => {
                const cellValue = row.values[columnId];
                const textValue = extractTextContent(cellValue);
                return textValue.toLowerCase().includes(lowercasedFilter);
            });
        });
    };

    const sortFunction = (rowA, rowB, columnId) => {
        const a = extractTextContent(rowA.values[columnId]).toLowerCase();
        const b = extractTextContent(rowB.values[columnId]).toLowerCase();

        const isNumericOrSpecialA = /^\d|[^a-zA-Z]/.test(a);
        const isNumericOrSpecialB = /^\d|[^a-zA-Z]/.test(b);

        if (isNumericOrSpecialA && !isNumericOrSpecialB) {
            return 1;
        }
        if (!isNumericOrSpecialA && isNumericOrSpecialB) {
            return -1;
        }

        // Handle numeric sorting
        const numA = parseFloat(a);
        const numB = parseFloat(b);

        if (!isNaN(numA) && !isNaN(numB)) {
            return numA > numB ? 1 : -1;
        }

        // Handle string sorting
        return a.localeCompare(b);
    };


    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            globalFilter: globalFilterFunction,
            sortTypes: {
                customSort: sortFunction,
            },
            autoResetSortBy: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = tableInstance;

    useEffect(() => {
        setPageSize(defaultValue || 10);
    }, [defaultValue, setPageSize]);

    useEffect(() => {
        const sortedData = [...table.rows].sort((a, b) => {
            for (let i = 0; i < Object.keys(sortState).length; i++) {
                const sort = sortState[Object.keys(sortState)[i]];
                const aVal = extractTextContent(a[sort.id]);
                const bVal = extractTextContent(b[sort.id]);

                const comparison = sortFunction({ values: { [sort.id]: aVal } }, { values: { [sort.id]: bVal } }, sort.id);

                if (comparison !== 0) {
                    return sort.desc ? -comparison : comparison;
                }
            }
            return 0;
        });

        setData([...sortedData]);
    }, [sortState, table.rows]);

    const setEntriesPerPage = (value) => setPageSize(value);

    const renderPagination = pageOptions.map((option) => (
        <MDPagination
            item
            key={option}
            onClick={() => gotoPage(Number(option))}
            active={pageIndex === option}
        >
            {option + 1}
        </MDPagination>
    ));

    const handleInputPagination = ({ target: { value } }) =>
        value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

    const customizedPageOptions = pageOptions.map((option) => option + 1);

    const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

    const [search, setSearch] = useState(globalFilter);

    const onSearchChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
        setSearch(value);
    }, 100);

    const handleSort = (column) => {
        const currentSort = sortState[column.id];

        let newSortState = {};
        if (!currentSort) {
            newSortState[column.id] = { id: column.id, desc: false };
        } else if (currentSort.desc) {
            newSortState = {};
        } else {
            newSortState[column.id] = { id: column.id, desc: true };
        }

        setSortState(newSortState);
    };

    const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

    let entriesEnd;

    if (pageIndex === 0) {
        entriesEnd = pageSize;
    } else if (pageIndex === pageOptions.length - 1) {
        entriesEnd = rows.length;
    } else {
        entriesEnd = pageSize * (pageIndex + 1);
    }

    return (
        <TableContainer sx={{ boxShadow: "none" }}>
            {entriesPerPage || canSearch ? (
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    {entriesPerPage && (
                        <MDBox display="flex" alignItems="center">
                            <Autocomplete
                                disableClearable
                                value={pageSize.toString()}
                                options={entries}
                                onChange={(event, newValue) => {
                                    setEntriesPerPage(parseInt(newValue, 10));
                                }}
                                size="small"
                                sx={{ width: "5rem" }}
                                renderInput={(params) => <MDInput {...params} />}
                            />
                            <MDTypography variant="caption" color="secondary">
                                &nbsp;&nbsp;rodyti puslapyje
                            </MDTypography>
                        </MDBox>
                    )}
                    {canSearch && (
                        <MDBox width="12rem" ml="auto">
                            <MDInput
                                placeholder="Ieškoti..."
                                value={search || ""}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }) => {
                                    setSearch(currentTarget.value);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </MDBox>
                    )}
                </MDBox>
            ) : null}
            <Table {...getTableProps()}>
                <MDBox component="thead">
                    {headerGroups.map((headerGroup) => {
                        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                        return (
                            <TableRow key={key} {...restHeaderGroupProps}>
                                {headerGroup.headers.map((column) => {
                                    const { key, ...restColumnProps } = column.getHeaderProps(column.getSortByToggleProps());
                                    return (
                                        <DataTableHeadCell
                                            key={key}
                                            {...restColumnProps}
                                            onClick={() => handleSort(column)}
                                            width={column.width ? column.width : "auto"}
                                            align={column.align ? column.align : "left"}
                                            sorted={sortState[column.id] ? (sortState[column.id].desc ? "desc" : "asc") : "none"}
                                        >
                                            {column.render("Header")}
                                        </DataTableHeadCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </MDBox>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        const { key, ...restRowProps } = row.getRowProps();
                        return (
                            <TableRow key={key} {...restRowProps}>
                                {row.cells.map((cell) => {
                                    const { key, ...restCellProps } = cell.getCellProps();
                                    return (
                                        <DataTableBodyCell
                                            key={key}
                                            noBorder={noEndBorder && rows.length - 1 === key}
                                            align={cell.column.align ? cell.column.align : "left"}
                                            {...restCellProps}
                                        >
                                            {cell.render("Cell")}
                                        </DataTableBodyCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <MDBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <MDBox mb={{ xs: 3, sm: 0 }}>
                        <MDTypography variant="button" color="secondary" fontWeight="regular">
                            Rodoma nuo {entriesStart} iki {entriesEnd} iš {rows.length} rastų rezultatų
                        </MDTypography>
                    </MDBox>
                )}
                {pageOptions.length > 1 && (
                    <MDPagination
                        variant={pagination.variant ? pagination.variant : "gradient"}
                        color={pagination.color ? pagination.color : "info"}
                    >
                        {canPreviousPage && (
                            <MDPagination item onClick={() => previousPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                            </MDPagination>
                        )}
                        {renderPagination.length > 6 ? (
                            <MDBox width="5rem" mx={1}>
                                <MDInput
                                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                                    value={customizedPageOptions[pageIndex]}
                                    onChange={(handleInputPagination, handleInputPaginationValue)}
                                />
                            </MDBox>
                        ) : (
                            renderPagination
                        )}
                        {canNextPage && (
                            <MDPagination item onClick={() => nextPage()}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                            </MDPagination>
                        )}
                    </MDPagination>
                )}
            </MDBox>
        </TableContainer>
    );
}

DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;
