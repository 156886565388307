import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const EditModal = ({ open, handleClose, user, handleSave }) => {
    const [formData, setFormData] = React.useState({ name: '', email: '', country: '' });

    React.useEffect(() => {
        if (user) {
            setFormData({ name: user.name, email: user.email, country: user.country });
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = () => {
        handleSave(formData);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 2,
                }
            }}
        >
            <DialogTitle sx={{ bgcolor: 'background.default', color: 'primary.contrastText', py:2 }}>Redaguoti vartotoją</DialogTitle>
            <DialogContent sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Vardas"
                    type="text"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    sx={{ color: 'text.primary', py:2 }}
                />
                <TextField
                    margin="dense"
                    name="email"
                    label="El. paštas"
                    type="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    sx={{ color: 'text.primary', py:2 }}
                />
                <TextField
                    margin="dense"
                    name="country"
                    label="Šalis"
                    type="text"
                    fullWidth
                    value={formData.country}
                    onChange={handleChange}
                    sx={{ color: 'text.primary', py:2 }}
                />
            </DialogContent>
            <DialogActions sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
                <Button onClick={handleClose} color="primary">
                    Uždaryti
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Išsaugoti
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    user: PropTypes.object,
    handleSave: PropTypes.func.isRequired,
};

export default EditModal;
