import HttpService from "./htttp.service";

export const setupAxiosInterceptors = (onUnauthenticated) => {
    const onRequestSuccess = async (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    };

    const onRequestFail = (error) => {
        return Promise.reject(error);
    };

    HttpService.addRequestInterceptor(onRequestSuccess, onRequestFail);

    const onResponseSuccess = (response) => {
        return response;
    };

    const onResponseFail = (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 403 || status === 401) {
            onUnauthenticated();
        }

        const requestUrl = error.config ? error.config.url : null;
        if (requestUrl && requestUrl.includes('logout')) {
            return Promise.resolve(true); // Return a resolved promise for logout
        } else {
            return Promise.reject(error);
        }
    };

    HttpService.addResponseInterceptor(onResponseSuccess, onResponseFail);
};
