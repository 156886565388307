/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../services/auth-service";
// Material Dashboard 2 React main context
const MaterialUI = createContext();

// authentication context
export const AuthContext = createContext({
    isAuthenticated: false,
    login: () => {},
    logout: () => {},
});

const AuthContextProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (token) {
            setIsAuthenticated(true);
            navigate(location);
        } else {
            logout();
        }
    }, []);

    const login = (token) => {
        localStorage.setItem("authToken", token);
        setIsAuthenticated(true);
        navigate("/pultas");
    };

    const logout = () => {
        localStorage.removeItem("authToken");
        setIsAuthenticated(false);
        navigate("/auth/login");
    };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
      case "INIT":
          return { ...state, ...action.value };
    case "MINISIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENTSIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITESIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAVCOLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENTNAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXEDNAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPENCONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
    const [controller, dispatch] = useReducer(reducer, {
        miniSidenav: false,
        transparentSidenav: false,
        whiteSidenav: false,
        sidenavColor: "info",
        transparentNavbar: true,
        fixedNavbar: true,
        openConfigurator: false,
        direction: "ltr",
        layout: "dashboard",
        darkMode: false,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeSettings = async () => {
            try {
                const settings = await AuthService.fetchSettings();
                const initialState = settings.reduce((acc, setting) => {
                    let value = setting.value;
                    if (value === "true" || value === "1") value = true;
                    else if (value === "false" || value === "0") value = false;
                    acc[setting.key] = value;
                    return acc;
                }, {});
                dispatch({ type: "INIT", value: initialState });
            } catch (error) {
                console.error("Failed to fetch settings", error);
            } finally {
                setLoading(false);
            }
        };

        initializeSettings();
    }, []);

    const saveSetting = async (key, value) => {
        try {
            await AuthService.saveSetting(key, value);
            dispatch({ type: key.toUpperCase(), value });
        } catch (error) {
            console.error(`Failed to save setting ${key}`, error);
        }
    };

    const value = useMemo(() => [controller, dispatch, saveSetting], [controller, dispatch]);

    return (
        <MaterialUI.Provider value={value}>
            {loading ? <div>Loading...</div> : children}
        </MaterialUI.Provider>
    );
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINISIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENTSIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITESIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAVCOLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENTNAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXEDNAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPENCONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });

export {
  AuthContextProvider,
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
