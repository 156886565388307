import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Autocomplete } from '@mui/material';

const UserCompanyEditModal = ({ open, handleClose, company, handleSave, cityOptions = [], ownerOptions = [], countryOptions = [] }) => {
    const [formData, setFormData] = React.useState({
        company_name: '',
        city: null,
        country: null,
        address: '',
        phone: '',
        owner: null,
    });

    React.useEffect(() => {
        if (company) {
            const matchedCity = cityOptions.find(option => option.id === Number(company.city_id)) || null;
            const matchedOwner = ownerOptions.find(option => option.id === Number(company.owner_id)) || null;
            const matchedCountry = countryOptions.find(option => option.id === Number(company.country_id)) || null;

            setFormData({
                company_name: company.company_name,
                city: matchedCity,
                country: matchedCountry,
                address: company.address,
                phone: company.phone,
                owner: matchedOwner,
            });
        }
    }, [company, cityOptions, ownerOptions, countryOptions]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleAutocompleteChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = () => {
        const updatedData = {
            ...formData,
            city_id: formData.city ? formData.city.id : null,
            owner_id: formData.owner ? formData.owner.id : null,
            country_id: formData.country ? formData.country.id : null,
        };
        handleSave(updatedData);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    bgcolor: 'background.default',
                    color: 'text.primary',
                    p: 2,
                }
            }}
        >
            <DialogTitle sx={{ bgcolor: 'background.default', color: 'primary.contrastText', py: 2 }}>Redaguoti įmonę</DialogTitle>
            <DialogContent sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
                <TextField
                    autoFocus
                    margin="dense"
                    name="company_name"
                    label="Įmonės pavadinimas"
                    type="text"
                    fullWidth
                    value={formData.company_name}
                    onChange={handleChange}
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.7)' },
                    }}
                    InputProps={{
                        style: { height: '3.5em', paddingTop: '0', paddingBottom: '0', display: 'flex', alignItems: 'center' },
                    }}
                    sx={{ color: 'text.primary', py: 2 }}
                />
                <Autocomplete
                    options={cityOptions}
                    getOptionLabel={(option) => option.name}
                    value={formData.city}
                    onChange={(event, newValue) => handleAutocompleteChange('city', newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            label="Miestas"
                            fullWidth
                            InputLabelProps={{
                                style: { color: 'rgba(255, 255, 255, 0.7)' },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                style: { height: '3.5em', paddingTop: '0', paddingBottom: '0', display: 'flex', alignItems: 'center' },
                            }}
                            sx={{ py: 2 }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>{option.name}</li>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                />
                <Autocomplete
                    options={countryOptions}
                    getOptionLabel={(option) => option.name}
                    value={formData.country}
                    onChange={(event, newValue) => handleAutocompleteChange('country', newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            label="Šalis"
                            fullWidth
                            InputLabelProps={{
                                style: { color: 'rgba(255, 255, 255, 0.7)' },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                style: { height: '3.5em', paddingTop: '0', paddingBottom: '0', display: 'flex', alignItems: 'center' },
                            }}
                            sx={{ py: 2 }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>{option.name}</li>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                />
                <TextField
                    margin="dense"
                    name="address"
                    label="Adresas"
                    type="text"
                    fullWidth
                    value={formData.address}
                    onChange={handleChange}
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.7)' },
                    }}
                    InputProps={{
                        style: { height: '3.5em', paddingTop: '0', paddingBottom: '0', display: 'flex', alignItems: 'center' },
                    }}
                    sx={{ color: 'text.primary', py: 2 }}
                />
                <TextField
                    margin="dense"
                    name="phone"
                    label="Telefonas"
                    type="text"
                    fullWidth
                    value={formData.phone}
                    onChange={handleChange}
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.7)' },
                    }}
                    InputProps={{
                        style: { height: '3.5em', paddingTop: '0', paddingBottom: '0', display: 'flex', alignItems: 'center' },
                    }}
                    sx={{ color: 'text.primary', py: 2 }}
                />
                <Autocomplete
                    options={ownerOptions}
                    getOptionLabel={(option) => option.name}
                    value={formData.owner}
                    onChange={(event, newValue) => handleAutocompleteChange('owner', newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            label="Savininkas"
                            fullWidth
                            InputLabelProps={{
                                style: { color: 'rgba(255, 255, 255, 0.7)' },
                            }}
                            InputProps={{
                                ...params.InputProps,
                                style: { height: '3.5em', paddingTop: '0', paddingBottom: '0', display: 'flex', alignItems: 'center' },
                            }}
                            sx={{ py: 2 }}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    disableClearable
                    key={formData.owner ? formData.owner.id : 'owner-key'} // Ensure uniqueness of key
                />
            </DialogContent>
            <DialogActions sx={{ bgcolor: 'background.default', color: 'text.primary' }}>
                <Button onClick={handleClose} color="primary">
                    Uždaryti
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Išsaugoti
                </Button>
            </DialogActions>
        </Dialog>
    );
};

UserCompanyEditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    company: PropTypes.object,
    handleSave: PropTypes.func.isRequired,
    cityOptions: PropTypes.array.isRequired,
    ownerOptions: PropTypes.array.isRequired,
    countryOptions: PropTypes.array.isRequired, // Added prop validation for country options
};

export default UserCompanyEditModal;
