import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import AuthService from "../../../../services/auth-service";

const UsersTable = ({ limit, disablePagination, disableSearch, disableEntriesPerPage }) => {
    const [columns] = useState([
        { Header: "Vartotojo vardas", accessor: "user", width: "20%", align: "left" },
        { Header: "Data", accessor: "created_at", width: "15%", align: "center" },
        { Header: "Vietovė", accessor: "country", width: "15%", align: "center" },
        { Header: "email", accessor: "email", width: "15%", align: "left" },
        { Header: "Ip", accessor: "ip", width: "15%", align: "left" },
    ]);

    const [rows, setRows] = useState([]);

    const fetchData = async () => {
        try {
            const usersData = await AuthService.fetchUsers();
            const rowsData = usersData.slice(0, limit || usersData.length).map((user) => ({
                user: (
                    <MDTypography component="a" href="#" variant="button" color="dark" fontWeight="medium">
                        {user.name}
                    </MDTypography>
                ),
                created_at: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {new Date(user.created_at).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '.')}
                    </MDTypography>
                ),
                email: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {user.email}
                    </MDTypography>
                ),
                country: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {user.country}
                    </MDTypography>
                ),
                ip: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {user.ip}
                    </MDTypography>
                ),
            }));
            setRows(rowsData);
        } catch (error) {
            console.error("There was an error fetching the users data!", error);
        }
    };

    useEffect(() => {
        fetchData().then(r => {

        });
    }, [limit]);

    return (
        <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Paskutiniai užsiregistravę
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <DataTable
                                table={{ columns, rows }}
                                isSorted={true}
                                canSearch={!disableSearch}
                                entriesPerPage={!disableEntriesPerPage}
                                showTotalEntries={!disableEntriesPerPage}
                                noEndBorder
                            />
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </MDBox>
    );
};

export default UsersTable;
