/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav  .

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";

import Login from "auth/login";
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";

// @mui icons
import Icon from "@mui/material/Icon";
import UserCompanyManagement from "./layouts/user-company-management";
import BlockedUsers from "./layouts/blocked-users";

const routes = [
    {
        type: "examples",
        name: "Pultas",
        key: "pultas",
        icon: <Icon fontSize="small">assessment</Icon>,
        route: "/pultas",
        component: <Dashboard/>,
    },
    {
        type: "examples",
        name: "Profilis",
        key: "profilis",
        icon: <Icon fontSize="small">person</Icon>,
        route: "/profilis",
        component: <UserProfile/>,
    },
    {
        type: "collapse",
        name: "Vartotojai",
        key: "vartotojai",
        icon: <Icon fontSize="small">people</Icon>,
        route: "/vartotojai",
        component: <UserManagement/>,
        collapse: [
            {
                name: "Įmonės",
                key: "imones",
                icon: <Icon fontSize="small" sx={{ml: 2}}>business</Icon>,
                route: "/vartotojai/imones",
                component: <UserCompanyManagement/>,
            },
            {
                name: "Blokuoti",
                key: "blokuoti",
                icon: <Icon fontSize="small" sx={{ml: 2}}>block</Icon>,
                route: "/vartotojai/blokuoti",
                component: <BlockedUsers/>,
            },
        ],
    },
    {
        type: "examples",
        name: "Tables",
        key: "tables",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/tables",
        component: <Tables/>,
    },
    {
        type: "examples",
        name: "Billing",
        key: "billing",
        icon: <Icon fontSize="small">receipt_long</Icon>,
        route: "/billing",
        component: <Billing/>,
    },
    {
        type: "examples",
        name: "Notifications",
        key: "notifications",
        icon: <Icon fontSize="small">notifications</Icon>,
        route: "/notifications",
        component: <Notifications/>,
    },
    {
        type: "examples",
        name: "Profile",
        key: "profile",
        icon: <Icon fontSize="small">person</Icon>,
        route: "/profile",
        component: <Profile/>,
    },
    {
        type: "examples",
        name: "Sign In",
        key: "sign-in",
        icon: <Icon fontSize="small">login</Icon>,
        route: "/authentication/sign-in",
        component: <SignIn/>,
    },
    {
        type: "examples",
        name: "Sign Up",
        key: "sign-up",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/authentication/sign-up",
        component: <SignUp/>,
    },
    {
        type: "auth",
        name: "Login",
        key: "login",
        icon: <Icon fontSize="small">login</Icon>,
        route: "/auth/login",
        component: <Login/>,
    },
    {
        type: "auth",
        name: "Register",
        key: "register",
        icon: <Icon fontSize="small">reigster</Icon>,
        route: "/auth/register",
        component: <Register/>,
    },
    {
        type: "auth",
        name: "Forgot Password",
        key: "forgot-password",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/auth/forgot-password",
        component: <ForgotPassword/>,
    },
    {
        type: "auth",
        name: "Reset Password",
        key: "reset-password",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/auth/reset-password",
        component: <ResetPassword/>,
    },
];

export default routes;
