import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import AuthService from "../../services/auth-service";
import MDButton from "components/MDButton";
import BlockIpModal from "components/MDModals/BlockIpModal";
import MDAlert from "components/MDAlert";

const BlockedUsers = () => {
    const [columns] = useState([
        { Header: "IP", accessor: "ip", width: "50%", align: "left" },
        { Header: "Blokavimo data", accessor: "blocked_at", width: "50%", align: "center" },
    ]);

    const [rows, setRows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: "", color: "info" });

    const fetchData = async () => {
        try {
            const bannedIps = await AuthService.fetchBannedIps();
            const rowsData = bannedIps.map((ban) => ({
                ip: (
                    <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                        {ban.ip}
                    </MDTypography>
                ),
                blocked_at: (
                    <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
                        {new Date(ban.date).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '.')}
                    </MDTypography>
                ),
            }));
            setRows(rowsData);
        } catch (error) {
            console.error("There was an error fetching the blocked IPs data!", error);
        }
    };

    const handleBlockIp = async (ip) => {
        try {
            const response = await AuthService.blockIp(ip);
            setNotification({ show: true, message: response.message, color: "success" });
            setIsModalOpen(false); // Close the modal upon success
            await fetchData(); // Refresh the data after blocking an IP
        } catch (error) {
            console.error("Failed to block IP:", error);
            setNotification({ show: true, message: "Failed to block IP.", color: "error" });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (notification.show) {
            const timer = setTimeout(() => {
                setNotification({ show: false, message: "", color: "info" });
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [notification]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {notification.show && (
                <MDAlert color={notification.color} mt="20px">
                    <MDTypography variant="body2" color="white">
                        {notification.message}
                    </MDTypography>
                </MDAlert>
            )}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Blokuojami IP adresai
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <MDButton
                                    color="primary"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Pridėti IP adresą
                                </MDButton>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    canSearch={true}
                                    entriesPerPage={true}
                                    showTotalEntries={true}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <BlockIpModal
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                handleSave={handleBlockIp}
            />
            <Footer />
        </DashboardLayout>
    );
};

export default BlockedUsers;
