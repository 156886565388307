// src/services/auth-service.js
import HttpService from "./htttp.service";

class AuthService {
    login = async (payload) => {
        const loginEndpoint = 'login';
        const response = await HttpService.post(loginEndpoint, payload);
        if (response.access_token) {
            localStorage.setItem("token", response.access_token);
        }
        return response;
    };

    register = async (credentials) => {
        const registerEndpoint = 'register';
        return await HttpService.post(registerEndpoint, credentials);
    };

    logout = async () => {
        const logoutEndpoint = 'logout';
        localStorage.clear();
        return await HttpService.post(logoutEndpoint);
    };

    forgotPassword = async (payload) => {
        const forgotPassword = 'password-forgot';
        return await HttpService.post(forgotPassword, payload);
    }

    resetPassword = async (credentials) => {
        const resetPassword = 'password-reset';
        return await HttpService.post(resetPassword, credentials);
    }

    getProfile = async() => {
        const getProfile = 'me';
        return await HttpService.get(getProfile);
    }

    updateProfile = async (newInfo) => {
        const updateProfile = "me";
        return await HttpService.patch(updateProfile, newInfo);
    }

    fetchUsers = async () => {
        const fetchUsers = "get-users";
        return await HttpService.get(fetchUsers);
    }

    fetchBannedIps = async () => {
        const fetchBannedIps = "get-bans";
        return await HttpService.get(fetchBannedIps);
    }

    updateUser = async (userId, userInfo) => {
        const updateUserEndpoint = `update-user/${userId}`;
        return await HttpService.post(updateUserEndpoint, userInfo);
    };

    blockUser = async (userId) => {
        const blockUserEndpoint = `block-user/${userId}`;
        return await HttpService.post(blockUserEndpoint);
    };

    blockIp = async (ip) => {
        const blockIpEndpoint = `block-ip/${ip}`;
        return await HttpService.post(blockIpEndpoint);
    };

    activateUser = async (userId) => {
        const activateUserEndpoint = `activate-user/${userId}`;
        return await HttpService.post(activateUserEndpoint);
    };

    fetchCompanies = async () => {
        const fetchCompaniesEndpoint = "get-companies";
        return await HttpService.get(fetchCompaniesEndpoint);
    };

    updateCompany = async (companyId, companyInfo) => {
        const updateCompanyEndpoint = `update-company/${companyId}`;
        return await HttpService.post(updateCompanyEndpoint, companyInfo);
    };

    refreshAdDates = async (companyId) => {
        const refreshAdDatesEndpoint = `refresh-ad-dates/${companyId}`;
        return await HttpService.post(refreshAdDatesEndpoint);
    };

    fetchCountryOptions = async () => {
        const fetchCountryOptionsEndpoint = "get-country-options";
        return await HttpService.get(fetchCountryOptionsEndpoint);
    };

    fetchCityOptions = async () => {
        const fetchCityOptionsEndpoint = "get-city-options";
        return await HttpService.get(fetchCityOptionsEndpoint);
    };

    fetchOwnerOptions = async () => {
        const fetchOwnerOptionsEndpoint = "get-owner-options";
        return await HttpService.get(fetchOwnerOptionsEndpoint);
    };

    fetchDashboardData = async () => {
        const dashboardDataEndpoint = 'get-dashboard-data';
        return await HttpService.get(dashboardDataEndpoint);
    };

    fetchInfo = async () => {
        const infoDataEndpoint = 'get-info-data';
        return await HttpService.get(infoDataEndpoint);
    };

    fetchSettings = async () => {
        const fetchSettings = "get-settings";
        return await HttpService.get(fetchSettings);
    };

    saveSetting = async (key, value) => {
        const saveSetting = 'save-setting';
        return await HttpService.post(saveSetting, { key, value });
    };

}

const authServiceInstance = new AuthService();
export default authServiceInstance;
