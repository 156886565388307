import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import authServiceInstance from "../../services/auth-service";
import UsersTable from "./components/Users";
import InfoTable from "./components/Info";

function Dashboard() {
    const [dashboardData, setDashboardData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await authServiceInstance.fetchDashboardData();
            setDashboardData(data);
        };
        fetchData();
    }, []);

    if (!dashboardData) {
        return <div>Kraunasi...</div>;
    }

    const pageInfoData = {
        labels: ["Kitas", "Pradinis", "Kontaktai", "Prisijungimas/Registracija"],
        datasets: {
            label: "Puslapio paspaudimai",
            data: [145184114, 11215738, 155491, 234836],
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1,
            barThickness: 20,
            maxBarThickness: 20,
        }
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                type: 'logarithmic',
                min: 1,
                ticks: {
                    callback: function(value) {
                        return Number(value.toString());
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += Number(context.raw).toLocaleString();
                        return label;
                    }
                }
            }
        }
    };

    const recentActivitiesData = {
        labels: dashboardData.recent_activities?.map((activity) => activity.date) || [],
        datasets: [{
            label: "Naujausi veiksmai",
            data: dashboardData.recent_activities?.map((activity) => activity.activity_count) || [],
            backgroundColor: "rgba(54, 162, 235, 0.4)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
            fill: true,
            tension: 0.4,
        }],
    };

    const userSignupsData = {
        labels: dashboardData.user_signups?.map((signup) => `Savaitė ${signup.week}`) || [],
        datasets: [{
            label: "Vartotojų registracijos",
            data: dashboardData.user_signups?.map((signup) => signup.signup_count) || [],
            backgroundColor: "rgba(255, 206, 86, 0.4)",
            borderColor: "rgba(255, 206, 86, 1)",
            borderWidth: 1,
            fill: true,
            tension: 0.4,
        }],
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="dark"
                                icon="weekend"
                                title="Iš viso puslapio paspaudimų"
                                count={dashboardData.total_page_hits}
                                percentage={{
                                    color: "success",
                                    amount: "+55%",
                                    label: "nei praėjusią savaitę",
                                }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                icon="leaderboard"
                                title="Šiandienos vartotojai"
                                count={dashboardData.latest_users?.length || 0}
                                percentage={{
                                    color: "success",
                                    amount: "+3%",
                                    label: "nei praėjusį mėnesį",
                                }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="success"
                                icon="store"
                                title="Naujausi veiksmai"
                                count={dashboardData.recent_activities?.length || 0}
                                percentage={{
                                    color: "success",
                                    amount: "+1%",
                                    label: "nei vakar",
                                }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MDBox mb={1.5}>
                            <ComplexStatisticsCard
                                color="primary"
                                icon="person_add"
                                title="Vartotojų registracijos"
                                count={dashboardData.user_signups?.length || 0}
                                percentage={{
                                    color: "success",
                                    amount: "",
                                    label: "Ką tik atnaujinta",
                                }}
                            />
                        </MDBox>
                    </Grid>
                </Grid>
                <MDBox mt={4.5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsBarChart
                                    color="info"
                                    title="Puslapio peržiūros"
                                    description="Puslapio paspaudimų statistika"
                                    date="Atnaujinta neseniai"
                                    chart={pageInfoData}
                                    options={chartOptions}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsLineChart
                                    color="success"
                                    title="Naujausi veiksmai"
                                    description={
                                        <>
                                            (<strong>Naujausi veiksmai</strong>) pagal naujausius duomenis.
                                        </>
                                    }
                                    date="Atnaujinta ką tik"
                                    chart={recentActivitiesData}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsLineChart
                                    color="dark"
                                    title="Vartotojų registracijos"
                                    description="Paskutinės registracijų statistikos"
                                    date="Atnaujinta dabar"
                                    chart={userSignupsData}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox py={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={8}>
                            <UsersTable
                                limit={10}
                                disablePagination={true}
                                disableSearch={true}
                                disableEntriesPerPage={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <InfoTable />
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Dashboard;
