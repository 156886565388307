import { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { ListItem } from "@mui/material";
import {
    useMaterialUIController,
    setMiniSidenav,
    setTransparentSidenav,
    setWhiteSidenav,
} from "context";

function Sidenav({ color = "info", brand = "", brandName, routes = [], ...rest }) {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
    const location = useLocation();
    const collapseName = location.pathname.replace("/", "");
    const [open, setOpen] = useState({});

    let textColor = "white";

    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = "dark";
    } else if (whiteSidenav && darkMode) {
        textColor = "inherit";
    }

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
        }

        window.addEventListener("resize", handleMiniSidenav);
        handleMiniSidenav();

        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location, transparentSidenav, whiteSidenav]);

    useEffect(() => {
        // Determine which parent should be open based on the current route
        routes.forEach((route) => {
            if (route.collapse) {
                route.collapse.forEach((subRoute) => {
                    if (location.pathname.startsWith(subRoute.route)) {
                        setOpen((prevState) => ({ ...prevState, [route.key]: true }));
                    }
                });
            }
        });
    }, [location.pathname, routes]);

    const handleToggle = (key) => {
        setOpen((prevState) => ({ ...prevState, [key]: !prevState[key] }));
    };

    const renderRoutes = routes
        .filter(route => route.type !== "auth")
        .map(({ type, name, icon, key, route, collapse }) => {
            let returnValue;

            if (type === "collapse") {
                returnValue = (
                    <div key={key}>
                        <NavLink to={route} style={{ textDecoration: "none", color: "inherit" }}>
                            <ListItem button onClick={() => handleToggle(key)}>
                                <SidenavCollapse
                                    name={name}
                                    icon={icon}
                                    active={key === collapseName || location.pathname.startsWith(route)}
                                />
                                {open[key] ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
                            </ListItem>
                        </NavLink>
                        <Collapse in={open[key]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {collapse && collapse.map((subRoute) => (
                                    <ListItem button component={NavLink} to={subRoute.route} key={subRoute.key}>
                                        <SidenavCollapse
                                            name={subRoute.name}
                                            icon={subRoute.icon}
                                            active={location.pathname === subRoute.route}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    </div>
                );
            } else if (type === "title") {
                returnValue = (
                    <MDTypography
                        key={key}
                        color={textColor}
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="uppercase"
                        pl={3}
                        mt={2}
                        mb={1}
                        ml={1}
                    >
                        {name}
                    </MDTypography>
                );
            } else if (type === "divider") {
                returnValue = (
                    <Divider
                        key={key}
                        light={
                            (!darkMode && !whiteSidenav && !transparentSidenav) ||
                            (darkMode && !transparentSidenav && whiteSidenav)
                        }
                    />
                );
            } else {
                returnValue = (
                    <NavLink key={key} to={route}>
                        <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
                    </NavLink>
                );
            }

            return returnValue;
        });

    return (
        <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
        >
            <MDBox pt={3} pb={1} px={4} textAlign="center">
                <MDBox
                    display={{ xs: "block", xl: "none" }}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{ cursor: "pointer" }}
                >
                    <MDTypography variant="h6" color="secondary">
                        <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                    </MDTypography>
                </MDBox>
                <MDBox component={NavLink} to="/" display="flex" alignItems="center">
                    {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
                    <MDBox
                        width={"100%"}
                        whiteSpace={'normal'}
                        sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                    >
                        <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                            {brandName}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
            <Divider
                light={
                    (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav)
                }
            />
            <List>
                {renderRoutes}
            </List>
        </SidenavRoot>
    );
}

Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
