import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import AuthService from "../../services/auth-service";
import MDAlert from "../../components/MDAlert";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import EditModal from "../../components/MDModals/UserEditModal";

const UserManagement = () => {
    const [columns] = useState([
        { Header: "ID", accessor: "id", width: "8%", align: "left" },
        { Header: "Vartotojo vardas", accessor: "user", width: "20%", align: "left" },
        { Header: "Data", accessor: "created_at", width: "15%", align: "center" },
        { Header: "Vietovė", accessor: "country", width: "15%", align: "center" },
        { Header: "email", accessor: "email", width: "15%", align: "left" },
        { Header: "Ip", accessor: "ip", width: "15%", align: "left" },
        { Header: "Veiksmai", accessor: "action", width: "12%", align: "center" },
    ]);

    const [rows, setRows] = useState([]);
    const [notification, setNotification] = useState({ show: false, message: "" });
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setIsModalOpen(true);
    };

    const handleSaveUser = async (updatedUser) => {
        try {
            const response = await AuthService.updateUser(selectedUser.id, updatedUser);
            setNotification({ show: true, message: response.message });
            setIsModalOpen(false);
            await fetchData();
        } catch (error) {
            console.error("Failed to update user:", error);
            setNotification({ show: true, message: "Failed to update user." });
        }
    };


    const handleBlockUser = async (userId) => {
        try {
            const response = await AuthService.blockUser(userId);
            setNotification({ show: true, message: response.message });
            await fetchData();
        } catch (error) {
            console.error("Failed to block user:", error);
            setNotification({ show: true, message: "Failed to block user." });
        }
    };

    const handleActivateUser = async (userId) => {
        try {
            const response = await AuthService.activateUser(userId);
            setNotification({ show: true, message: response.message });
            await fetchData();
        } catch (error) {
            console.error("Failed to activate user:", error);
            setNotification({ show: true, message: "Failed to activate user." });
        }
    };

    const fetchData = async () => {
        try {
            const usersData = await AuthService.fetchUsers();
            const rowsData = usersData.map((user) => ({
                id: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {user.id}
                    </MDTypography>
                ),
                user: (
                    <MDTypography component="a" href="#" variant="button" color="dark" fontWeight="medium">
                        {user.name}
                    </MDTypography>
                ),
                created_at: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {new Date(user.created_at).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '.')}
                    </MDTypography>
                ),
                email: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {user.email}
                    </MDTypography>
                ),
                country: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {user.country}
                    </MDTypography>
                ),
                ip: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        {user.ip}
                    </MDTypography>
                ),
                action: (
                    <MDBox>
                        <MDTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="error"
                            fontWeight="medium"
                            mr={2}
                            onClick={() => handleBlockUser(user.id)}
                        >
                            Blokuoti
                        </MDTypography>
                        <MDTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color={user.active ? "success" : "warning"}
                            fontWeight="medium"
                            mr={2}
                            onClick={() => handleActivateUser(user.id)}
                        >
                            {user.active ? "Aktyvus" : "Neaktyvus"}
                        </MDTypography>
                        <MDTypography
                            component="a"
                            href="#"
                            variant="caption"
                            color="text"
                            fontWeight="medium"
                            onClick={() => handleEditUser(user)}
                        >
                            <Tooltip title="Redaguoti" placement="top">
                                <Icon sx={{ cursor: "pointer" }} fontSize="small">
                                    edit
                                </Icon>
                            </Tooltip>
                        </MDTypography>
                    </MDBox>
                ),
            }));
            setRows(rowsData);
        } catch (error) {
            console.error("There was an error fetching the users data!", error);
        }
    };

    useEffect(() => {
        fetchData().then(r => {
            console.log('')
        });
    }, []);

    useEffect(() => {
        if (notification.show === true) {
            setTimeout(() => {
                setNotification(true);
            }, 3000);
        }
    }, [notification]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {notification.show && (
                <MDAlert color="secondary" mt="20px">
                    <MDTypography variant="body2" color="white">
                        {notification.message}
                    </MDTypography>
                </MDAlert>
            )}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Vartotojai
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    canSearch={true}
                                    entriesPerPage={true}
                                    showTotalEntries={true}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <EditModal
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                user={selectedUser}
                handleSave={handleSaveUser}
            />
            <Footer />
        </DashboardLayout>
    );
};

export default UserManagement;
