import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for the dropdown
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import AuthService from "../../services/auth-service";
import MDAlert from "components/MDAlert";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import EditModal from "../../components/MDModals/UserCompanyEditModal";

// Debounce function to limit the number of requests sent
const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const UserCompanyManagement = () => {
    const [columns] = useState([
        { Header: "Įmonės pavadinimas", accessor: "company_name", width: "20%", align: "left" },
        { Header: "Šalis", accessor: "country", width: "15%", align: "left" },
        { Header: "Miestas / Adresas / Telefonas", accessor: "location", width: "25%", align: "left" },
        { Header: "Aktyvus", accessor: "active", width: "10%", align: "center" },
        { Header: "Vartotojas", accessor: "owner", width: "10%", align: "left" },
        { Header: "Peržiūros", accessor: "views", width: "8%", align: "left" },
        { Header: "Kainoraštis", accessor: "pricelist", width: "10%", align: "left" },
        { Header: "Atnaujinti", accessor: "actions", width: "12%", align: "left" },
    ]);

    const [rows, setRows] = useState([]);
    const [filters, setFilters] = useState({ company_name: '', country: '', city: '', active: '', global: '' });
    const [notification, setNotification] = useState({ show: false, message: "", color: "info" });
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cityOptions, setCityOptions] = useState([]);
    const [ownerOptions, setOwnerOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]); // Added country options state

    const fetchCityOptions = async () => {
        try {
            const response = await AuthService.fetchCityOptions();
            setCityOptions(response);
        } catch (error) {
            console.error("Nepavyko gauti miestų sąrašo:", error);
        }
    };

    const fetchOwnerOptions = async () => {
        try {
            const response = await AuthService.fetchOwnerOptions();
            setOwnerOptions(response);
        } catch (error) {
            console.error("Nepavyko gauti savininkų sąrašo:", error);
        }
    };

    const fetchCountryOptions = async () => { // Fetch countries
        try {
            const response = await AuthService.fetchCountryOptions();
            setCountryOptions(response);
        } catch (error) {
            console.error("Nepavyko gauti šalių sąrašo:", error);
        }
    };

    const handleEditCompany = (company) => {
        setSelectedCompany(company);
        setIsModalOpen(true);
    };

    const handleSaveCompany = async (updatedCompany) => {
        try {
            const response = await AuthService.updateCompany(selectedCompany.id, updatedCompany);
            setNotification({ show: true, message: response.message || "Įmonė sėkmingai atnaujinta", color: "success" });
            setIsModalOpen(false);
            await fetchData();
        } catch (error) {
            console.error("Nepavyko atnaujinti įmonės:", error);
            setNotification({ show: true, message: "Nepavyko atnaujinti įmonės.", color: "error" });
        }
    };

    const handleToggleActive = async (company) => {
        try {
            const updatedCompany = { ...company, active: !company.active };
            const response = await AuthService.updateCompany(company.id, updatedCompany);
            setNotification({ show: true, message: response.message || "Įmonės aktyvumo būsena atnaujinta", color: "success" });
            await fetchData();
        } catch (error) {
            console.error("Nepavyko pakeisti aktyvumo būsenos:", error);
            setNotification({ show: true, message: "Nepavyko pakeisti aktyvumo būsenos.", color: "error" });
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handleRefreshAdDates = async (companyId) => {
        try {
            const response = await AuthService.refreshAdDates(companyId);
            setNotification({ show: true, message: response.message || "Skelbimų datos sėkmingai atnaujintos", color: "success" });
            await fetchData();
        } catch (error) {
            console.error("Nepavyko atnaujinti skelbimų datų:", error);
            setNotification({ show: true, message: "Nepavyko atnaujinti skelbimų datų.", color: "error" });
        }
    };

    useEffect(() => {
        if (notification.show) {
            const timer = setTimeout(() => {
                setNotification({ show: false, message: "", color: "info" });
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [notification]);
    useCallback((data) => {
        return data.filter(company => {
            const globalFilter = filters.global.toLowerCase();
            return (
                (!filters.company_name || company.company_name.toLowerCase().includes(filters.company_name.toLowerCase())) &&
                (!filters.country || company.country.toLowerCase().includes(filters.country.toLowerCase())) &&
                (!filters.city || company.city.toLowerCase().includes(filters.city.toLowerCase())) &&
                (!filters.active || (filters.active === 'Taip' ? company.active : filters.active === 'Ne' ? !company.active : true)) &&
                (
                    !globalFilter ||
                    company.company_name.toLowerCase().includes(globalFilter) ||
                    company.country.toLowerCase().includes(globalFilter) ||
                    company.city.toLowerCase().includes(globalFilter) ||
                    company.address.toLowerCase().includes(globalFilter) ||
                    company.phone.toLowerCase().includes(globalFilter) ||
                    company.owner.toLowerCase().includes(globalFilter)
                )
            );
        });
    }, [filters]);
    const fetchData = async () => {
        try {
            const rawCompaniesData = await AuthService.fetchCompanies();

            // Apply filters before grouping
            const filteredCompanies = rawCompaniesData.flatMap(group => {
                return group.companies.filter(company => {
                    const globalFilter = filters.global.toLowerCase();
                    return (
                        (!filters.company_name || company.company_name.toLowerCase().includes(filters.company_name.toLowerCase())) &&
                        (!filters.country || group.country.toLowerCase().includes(filters.country.toLowerCase())) &&
                        (!filters.city || company.city.toLowerCase().includes(filters.city.toLowerCase())) &&
                        (!filters.active || (filters.active === 'Taip' ? company.active : filters.active === 'Ne' ? !company.active : true)) &&
                        (
                            !globalFilter ||
                            company.company_name.toLowerCase().includes(globalFilter) ||
                            group.country.toLowerCase().includes(globalFilter) ||
                            company.city.toLowerCase().includes(globalFilter) ||
                            company.address.toLowerCase().includes(globalFilter) ||
                            company.phone.toLowerCase().includes(globalFilter) ||
                            company.owner.toLowerCase().includes(globalFilter)
                        )
                    );
                }).map(company => ({ ...company, country: group.country }));
            });

            const groupedCompanies = filteredCompanies.reduce((groups, company) => {
                const countryGroup = groups.find(group => group.country === company.country);
                if (!countryGroup) {
                    groups.push({ country: company.country, companies: [company] });
                } else {
                    countryGroup.companies.push(company);
                }
                return groups;
            }, []);

            const rowsData = groupedCompanies.reduce((acc, group) => {
                acc.push({
                    company_name: (
                        <MDTypography
                            component="div"
                            variant="h6"
                            color="info"
                            fontWeight="bold"
                            sx={{ mt: 2, mb: 1, borderBottom: '2px solid #ddd', pb: 1 }}
                        >
                            {group.country}
                        </MDTypography>
                    ),
                    country: null,
                    location: null,
                    active: null,
                    owner: null,
                    views: null,
                    pricelist: null,
                    actions: null,
                });

                group.companies.forEach((company) => {
                    acc.push({
                        company_name: (
                            <MDTypography component="a" href="#" variant="button" color="dark" fontWeight="medium">
                                {company.company_name || "Nenurodyta įmonė"}
                            </MDTypography>
                        ),
                        country: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                {company.country || "Nežinoma šalis"}
                            </MDTypography>
                        ),
                        location: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                {company.city}, {company.address}, {company.phone}
                            </MDTypography>
                        ),
                        active: (
                            <Checkbox
                                checked={Boolean(company.active)}
                                onChange={() => handleToggleActive(company)}
                                color="primary"
                            />
                        ),
                        owner: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                {company.owner || "Nenurodytas vartotojas"}
                            </MDTypography>
                        ),
                        views: (
                            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                                {company.views || 0}
                            </MDTypography>
                        ),
                        pricelist: (
                            <MDTypography component="a" href={company.pricelist_file} variant="caption" color="text" fontWeight="medium">
                                <Icon sx={{ cursor: "pointer" }} fontSize="medium">insert_drive_file</Icon>
                            </MDTypography>
                        ),
                        actions: (
                            <MDBox>
                                <MDTypography
                                    component="a"
                                    href="#"
                                    variant="caption"
                                    color="info"
                                    fontWeight="medium"
                                    mr={2}
                                    onClick={() => handleRefreshAdDates(company.id)}
                                >
                                    Atnaujinti
                                </MDTypography>
                                <MDTypography
                                    component="a"
                                    href="#"
                                    variant="caption"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => handleEditCompany(company)}
                                >
                                    <Tooltip title="Redaguoti" placement="top">
                                        <Icon sx={{ cursor: "pointer" }} fontSize="small">
                                            edit
                                        </Icon>
                                    </Tooltip>
                                </MDTypography>
                            </MDBox>
                        ),
                    });
                });

                return acc;
            }, []);

            setRows(rowsData);
        } catch (error) {
            console.error("Nepavyko gauti įmonių duomenų:", error);
        }
    };


    const debouncedFetchData = useCallback(debounce(fetchData, 500), [filters]); // Debounce the fetchData function

    useEffect(() => {
        debouncedFetchData();
        fetchCityOptions();
        fetchOwnerOptions();
        fetchCountryOptions();
    }, [filters, debouncedFetchData]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {notification.show && (
                <MDAlert color={notification.color} mt="20px">
                    <MDTypography variant="body2" color="white">
                        {notification.message}
                    </MDTypography>
                </MDAlert>
            )}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">Įmonės</MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <Grid container spacing={3} sx={{ mb: 3 }}>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Įmonės pavadinimas"
                                            name="company_name"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleFilterChange}
                                            value={filters.company_name}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Šalis"
                                            name="country"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleFilterChange}
                                            value={filters.country}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Miestas"
                                            name="city"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleFilterChange}
                                            value={filters.city}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            select
                                            label="Aktyvus"
                                            name="active"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleFilterChange}
                                            value={filters.active}
                                            SelectProps={{
                                                displayEmpty: true,
                                                inputProps: {
                                                    style: {
                                                        height: '1.4375em',
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        lineHeight: '1.4375em',
                                                    },
                                                },
                                                style: {
                                                    marginTop: '26px',
                                                },
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    marginTop: '-14px',
                                                },
                                            }}
                                        >
                                            <MenuItem value="">Visi</MenuItem>
                                            <MenuItem value="Taip">Taip</MenuItem>
                                            <MenuItem value="Ne">Ne</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Paieška"
                                            name="global"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleFilterChange}
                                            value={filters.global}
                                        />
                                    </Grid>
                                </Grid>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={true}
                                    canSearch={false}
                                    entriesPerPage={true}
                                    showTotalEntries={true}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <EditModal
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                company={selectedCompany}
                handleSave={handleSaveCompany}
                cityOptions={cityOptions}
                ownerOptions={ownerOptions}
                countryOptions={countryOptions}
            />
            <Footer />
        </DashboardLayout>
    );
};

export default UserCompanyManagement;
