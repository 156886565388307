import Axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
Axios.defaults.baseURL = API_URL;

export class HttpService {
    _axios = Axios.create({
        baseURL: API_URL,
        timeout: 3000,
        headers: { 'Content-Type': 'application/vnd.api+json' },
    });

    addRequestInterceptor = (onFulfilled, onRejected) => {
        this._axios.interceptors.request.use(onFulfilled, onRejected);
    };

    addResponseInterceptor = (onFulfilled, onRejected) => {
        this._axios.interceptors.response.use(onFulfilled, onRejected);
    };

    get = async (url) => await this.request(this.getOptionsConfig("get", url));

    post = async (url, data) => await this.request(this.getOptionsConfig("post", url, data));

    put = async (url, data) => await this.request(this.getOptionsConfig("put", url, data));

    patch = async (url, data) => await this.request(this.getOptionsConfig("patch", url, data));

    delete = async (url) => await this.request(this.getOptionsConfig("delete", url));

    getOptionsConfig = (method, url, data) => {
        return { method, url, data };
    };

    request(options) {
        return new Promise((resolve, reject) => {
            this._axios
                .request(options)
                .then((res) => resolve(res.data))
                .catch((ex) => {
                    if (ex.response) {
                        reject(ex.response.data);
                    } else if (ex.request) {
                        reject({ message: 'No response received', error: ex });
                    } else {
                        reject({ message: 'Error setting up request', error: ex });
                    }
                });
        });
    }
}

const httpServiceInstance = new HttpService();
export default httpServiceInstance;

